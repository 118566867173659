import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {IndexView} from "./view/index/IndexView";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
      <Routes>
        <Route path={"/"} element={< IndexView />} />
      </Routes>
  </BrowserRouter>
);

reportWebVitals(console.log);
