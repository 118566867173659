export const IndexView = () => {
    return (
        <>
            <div className="bg-gray-50 h-100 min-h-screen">
                <div
                    className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        <span className="block">O nouă poveste de succes ar putea să înceapă de aici!</span>
                        <span className="block text-blue-600">Alătura-te!</span><br/>
                        <span className={"text-sm"}>Ofertele de cumpărare sunt bine-venite.</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                            <a
                                href="https://discord.gg/3nbfqZ2"
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-indigo-700"
                            >
                                Intră pe Discord
                            </a>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    Powered by <a href={"https://andrei1058.dev"} target={"_blank"} rel={"noreferrer"} className={"text-blue-700"}>andrei1058.dev</a>
                </div>
            </div>
        </>
    )
}